import { FilterIcon } from '@heroicons/react/outline';
import { Checkbox, IconButton, Popover } from '@mui/material';
import { useRef, useState, useMemo } from 'react';
import { IntentReasonMap } from 'src/typings/call.types';
import { twMerge } from 'tailwind-merge';

type Props = {
  onFilterChange: (filters: any) => void;
  prevFilter: number[];
  intentType: 'intent' | 'manual_intent';
};

const hasAll = (arr: number[], targetArr: number[]): boolean => {
  let result = true;
  for (let i = 0; i < targetArr.length; i++) {
    const target = targetArr[i];
    let val = arr.includes(target);
    if (!val) {
      return false;
    }
  }

  return result;
};

const PICKED_QUICK_FILTER = [2, 3, 4, 5, 6, 7, 8, 13, 16, 17, 23, 24, 25, 26, 27, 28, 29];
const NOT_PICKED_QUICK_FILTER = [1, 9, 10, 11, 12, 14, 15, 18, 19, 20, 21, 22];

export default function UserIntentFilter({ onFilterChange, intentType, prevFilter }: Props) {
  const iconRef = useRef(null);
  const [selectedFilters, setSelectedFilters] = useState<number[]>(prevFilter || []);
  const [showFilter, toggleShowFilter] = useState<boolean>(false);

  const handleChange = (filterKey: number) => {
    const index = selectedFilters?.findIndex((l) => l === filterKey);
    let updatedFilters = [];
    if (index === -1) {
      updatedFilters = [...selectedFilters, filterKey];
      setSelectedFilters([...updatedFilters]);
    } else {
      updatedFilters = selectedFilters.filter((i) => i !== filterKey);
      setSelectedFilters([...updatedFilters]);
    }
    onFilterChange({ [intentType]: [...updatedFilters] });
  };

  const onAddBulk = (arr: number[], add: boolean) => {
    if (add) {
      let updatedFilters = [...selectedFilters];
      for (let i = 0; i < arr.length; i++) {
        const curr = arr[i];
        const index = selectedFilters?.findIndex((l) => l === curr);
        if (index === -1) {
          updatedFilters.push(curr);
        }
      }
      setSelectedFilters([...updatedFilters]);
      onFilterChange({ [intentType]: [...updatedFilters] });
    } else {
      let updatedFilters = [...selectedFilters];
      for (let i = 0; i < arr.length; i++) {
        const curr = arr[i];
        updatedFilters = updatedFilters.filter((l) => l !== curr);
      }
      setSelectedFilters([...updatedFilters]);
      onFilterChange({ [intentType]: [...updatedFilters] });
    }
  };

  const onClearAll = () => {
    setSelectedFilters([]);
    onFilterChange({ [intentType]: [] });
  };

  const isPickedQuickFilter = useMemo(() => hasAll(selectedFilters, PICKED_QUICK_FILTER), [selectedFilters]);
  const isNotPickedQuickFilter = useMemo(() => hasAll(selectedFilters, NOT_PICKED_QUICK_FILTER), [selectedFilters]);

  return (
    <>
      <div className="relative">
        <IconButton ref={iconRef} onClick={() => toggleShowFilter(!showFilter)}>
          <FilterIcon className="w-5 h-5 text-primaryButton" />
        </IconButton>

        {selectedFilters.length > 0 && (
          <div className="absolute top-0 right-0 w-4 h-4 bg-red-500 rounded-full flex justify-center items-center text-white text-[10px]">
            {selectedFilters.length}
          </div>
        )}
      </div>

      <Popover
        open={showFilter}
        onClose={() => toggleShowFilter(false)}
        anchorEl={iconRef.current}
        anchorOrigin={{
          vertical: 50, // 50 pixels offset from the top of the page
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="flex flex-col gap-y-2 p-4 w-[350px]">
          <div className="w-full flex justify-between items-center">
            <div className="text-lg font-medium">{`Filters (${selectedFilters.length})`}</div>
            <div
              onClick={onClearAll}
              className={twMerge(
                'text-sm cursor-pointer text-primaryButton',
                selectedFilters.length === 0 ? 'text-gray-400 cursor-not-allowed' : '',
              )}
            >
              Clear all
            </div>
          </div>
          <div className="flex flex-col gap-y-2">
            <div className="flex flex-col gap-y-1 h-80 overflow-auto">
              <div
                key={'qf-picked'}
                className="flex justify-start items-center gap-x-1 cursor-pointer hover:rounded-lg hover:bg-gray-100 transition-colors ease-in-out"
                onClick={() => onAddBulk(PICKED_QUICK_FILTER, !isPickedQuickFilter)}
              >
                <Checkbox checked={isPickedQuickFilter} />
                <div className="text-[12px] font-normal">PICKED</div>
              </div>
              <div
                key={'qf-not-picked'}
                className="flex justify-start items-center gap-x-1 cursor-pointer hover:rounded-lg hover:bg-gray-100 transition-colors ease-in-out"
                onClick={() => onAddBulk(NOT_PICKED_QUICK_FILTER, !isNotPickedQuickFilter)}
              >
                <Checkbox checked={isNotPickedQuickFilter} />
                <div className="text-[12px] font-normal">NOT PICKED</div>
              </div>
              <hr />
              {Object.entries(IntentReasonMap).map((item) => {
                const isSelected = selectedFilters.findIndex((i) => i === parseInt(item[0], 10)) !== -1;

                return (
                  <div
                    key={item[1]}
                    className="flex justify-start items-center gap-x-1 cursor-pointer hover:rounded-lg hover:bg-gray-100 transition-colors ease-in-out"
                    onClick={() => handleChange(parseInt(item[0], 10))}
                  >
                    <Checkbox checked={isSelected} />
                    <div className="text-[12px] font-normal">{item[1]}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Popover>
    </>
  );
}
